/* App.css */

/* Container for your entire app layout if you wish */
.App {
  text-align: center;
}

/* Common utility classes */
.highlight-green {
  border: 10px solid #2ecc71 !important; /* vibrant green */
  transition: border 0.3s ease;
  border-radius: 10px;
}
.highlight-red {
  border: 10px solid #e74c3c !important; /* vibrant red */
  transition: border 0.3s ease;
  border-radius: 10px;
}

/* Notification styling (position + fade in/out) */
.notification {
  position: fixed;
  bottom: 10%;
  right: 5%;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 0.8em 1em;
  border-radius: 0.5em;
  animation: fadeInOut 3s ease forwards;
  z-index: 9999;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(20px); }
  10% { opacity: 1; transform: translateY(0); }
  90% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(20px); }
}

/* Game flow wrapper */
.game-flow {
  padding: 1em;
}

/* For question type 1 + 2 containers */
.images-container,
.options-container {
  display: flex;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}

.option-img {
  width: auto;
  height: auto;
  border: 2px solid transparent;
  cursor: pointer;
}

.option-btn {
  font-size: 1rem;
  box-shadow: none;
  padding: 10px;
}

/* 
  Animate the homepage background with floating veggies :-)
  This is optional. We define a keyframe that moves "veggie shapes" 
  or sets a subtle color gradient shift.
*/
@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(-20px); }
  10% { opacity: 1; transform: translateY(0); }
  90% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
}

/* 
  HomePage 
  - We'll add a background repeating pattern or a sprite with veggies 
  - Then animate it with floatVeggies
*/
.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
}

.home-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1em 2em;
}

/* Language select bigger and playful */
.language-selector select {
  padding: 0.4em;
  font-size: 1rem;
  border: 2px solid #FF6F61; 
  background-color: #FFFFF0;
  color: #36454F;
}

/* Center content, use pun-based headings, etc. */
.home-content {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

/* Difficulty buttons with playful color on selected */
.difficulty-buttons {
  margin: 1em 0;
}
.selected {
  background-color: #FF6F61;
  color: #FFFFF0;
}

/* Start game button more vibrant */
.start-game-btn {
  background-color: #77AB59;
  margin: 1em 0;
}
.start-game-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Stopwatch + lives layout */
.stopwatch {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1em 0;
  text-align: center;
}
.lives-container {
  display: flex;
  justify-content: center;
  margin: 1em 0;
}
.lives-container img {
  width: 30px;
  margin: 0 5px;
}

/* Final screen / game over styling */
.final-screen,
.game-over-screen {
  padding: 2em;
  text-align: center;
}

.final-screen .actions,
.game-over-screen .actions {
  margin: 1em 0;
  display: flex;
  justify-content: center;
  gap: 1em;
}

/* 
  For question type 2: 
  - on desktop, place the image on one side, 
    the buttons in another "column" centered vertically
*/
.game-type-2 {
  display: flex;
  flex-direction: column; /* default for mobile */
  align-items: center;
  gap: 1em;
}

.game-type-1 h2 {
  text-align: center;
}

.game-type-2 h2 {
  text-align: center;
}


/* 
  Media Query for desktop:
  - 2 columns: image on left, buttons on right, 
    vertically centered 
*/
@media (min-width: 768px) {
  .game-type-2 {
    /*flex-direction: row;*/
    align-items: center; /* center them vertically */
    justify-content: center; /* horizontally center columns */
  }

  .main-image-container {
    flex: 1; /* image column */
    display: flex;
    justify-content: center;
  }

  .options-container {
    flex: 1; /* options column */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .notification {
    background-color: #77AB59;
    /* Green background */
    color: white;
    /* White text color */
    padding: 15px;
    position: fixed;
    /* Positioned relative to the viewport */
    bottom: 30px;
    /* 30px from the bottom */
    right: 30px;
    /* 30px from the right */
    z-index: 2;
    /* Sit on top */
    border-radius: 5px;
    /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Box shadow for a bit of depth */
}


  .leaderboard-submit input {
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
/* For small screens (mobile), show at top center */
@media (max-width: 600px) {
  .notification {
    bottom: auto;
    top: 30px;
}

  .images-container,
  .options-container {
    flex-direction: row;
  }

  .option-img {
    width: 100%; 
  }

  .leaderboard-submit input {
    margin-bottom: 10px;
    margin-right: 0px;
  }
}
