/* index.css */

/* 
  Use a fun, pun-based font for a playful vibe. 
  (Load it via <link> in public/index.html or import in JS if you want a custom font from Google Fonts, etc.)
  e.g., "Luckiest Guy" from Google Fonts, or "Comic Sans MS" for maximum pun potential :-)
*/
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito'; /* playful "pun-based" feel */
  background-color: #FFFFF0; /* a light pastel or playful base color */
  color: #36454f; /* slightly softer dark text color */
  height: 100%;
}

/* Make sure all elements use border-box sizing */
*, *::before, *::after {
  box-sizing: inherit;
}

/* Remove default list styles */
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Images: consistent sizing/spacing */
img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Links as normal text, override as needed */
a {
  text-decoration: none;
  color: inherit;
}

/* Basic button reset, now more vibrant/pun-based color */
button {
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
  background: #77AB59;
  border: 1px solid #77AB59;
  border-radius: 10px;
  box-shadow: 4px 4px 0px 4px #FF6F61;
  color: #FFFFF0;
  text-decoration: none !important;
  padding: 15px;
}

.difficulty-buttons button {
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: none;
}

.nav-buttons button {
  margin: 20px;
  box-shadow: none;
}
button:hover {
  background: #FF6F61;
}

.rules-page {
  text-align: center;
}

.rules-page div {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  font-size: 1.2em;
}

.rules-page ul {
  list-style-type: disc;
  padding-left: 1rem;
  list-style-position: outside;
}

.rules-page li::marker {
  color: black;
}


.leaderboard-page {
  text-align: center;
}

.leaderboard-page li{
  font-size: 1.5em;
  padding: 10px;
}

/* The homepage container should be relative, so the radar background is stacked behind content */
.home-page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(217deg, rgba(22,54,32,0.9), rgba(22,54,32,0) 70.71%),
            linear-gradient(127deg, rgba(12,84,42,0.9), rgba(12,84,42,0) 70.71%),
            linear-gradient(336deg, rgba(34,139,34,0.9), rgba(34,139,34,0) 70.71%);

}

.home-content h1{
  color: #FFFFF0;
}

/* Radar background behind all content */
.radar-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* behind other elements which have z-index:1 or default stacking */
  pointer-events: none; /* so user can't interact with it */
  background: none; /* no static color */
  overflow: hidden; /* keep targets inside */
}

.home-footer {
  z-index: 1;
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  color: #FFFFF0;
}


/* The "radar sweep" effect - an animated pseudo-element that 
   has a green arc or gradient that moves from bottom to top, 
   then dims out. We'll define it in a keyframe. */
.radar-background::before {
  content: "";
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 100%,
    rgba(0,255,0,0.3) 10%,
    transparent 70%
  );
  /* This radial gradient is an approximation of a curved fill from the bottom.
     Adjust the shape/size as you like. The circle is anchored at bottom center. */

  animation: radarSweep 9s infinite; /* total 9s cycle */
  opacity: 1;
}


/* The aim moves from corner to corner in a loop.
   Adjust keyframes for random movement if you want a more advanced approach. */
   @keyframes radarSweep {
    0% {
      transform: translateY(100%); /* start below the screen */
      opacity: 1;
    }
    66% {
      transform: translateY(0%); /* fully top */
      opacity: 1;
    }
    70% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
      transform: translateY(-10%); /* a bit above the top */
    }
  }
  
  .radar-target {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(0, 255, 0, 1);
    pointer-events: none;
    transform: translate(-50%, -50%);
    animation: expand 4s ease-out forwards;
  }
  
  @keyframes expand {
    0% {
      width: 5px;
      height: 5px;
      opacity: 1;
    }
    100% {
      width: 40px;
      height: 40px;
      opacity: 0.1;
    }
  }
  



.final-screen h2 {
  font-size: 2em;
}

.final-screen h3 {
  font-size: 2em;
}

.leaderboard-submit p {
  font-size: 1.2em;
}
.leaderboard-submit input {
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}
